<template>
  <div class="r-cover">
    <div v-if="guidedTours" class="r-cover flex-row md:flex">
      <div
        :key="index"
        v-for="(tour, index) in guidedTours"
        :id="`guided-tour-${index}`"
        class="guided-tour relative w-full md:w-1/3 md:h-full min-h-96 overflow-hidden flex justify-center items-center cursor-pointer opacity-0"
        :style="`background-color: ${tour.colour}`"
        @click="tourClick(tour)"
      >
        <div v-if="tour.featured_image" class="a-cover inset-0 overflow-hidden">
          <img
            class="guided-tour-img absolute right-0 bottom-0 w-auto max-w-none h-4/5 sm:h-3/5 opacity-0"
            :src="tour.featured_image.url"
            :alt="tour.featured_image.alt || `${tour.title.rendered} guided tour featured image`"
          />
        </div>
        <div class="relative">
          <Button
            class="guided-tour-btn relative opacity-0 bg-secondary py-3"
            :button="{
              button_text: tour.title.rendered,
              button_link: `/guided-tours/${tour.slug}`,
              button_label: `View ${tour.title.rendered} campus guided tour`
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'
import Button from '@/components/buttons/Button.vue'

let tl = null

export default {
  name: 'GuidedTours',

  components: {
    Button
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTimeline()
      tl.play()
    })
  },

  watch: {
    $route(from, to) {
      this.$nextTick(() => {
        if (from.meta.isGuidedTours && to.meta.isGuidedTours) {
          this.loadTimeline()
          tl.play()
        }
      })
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.animateOut(next)
  },

  beforeRouteLeave(to, from, next) {
    this.animateOut(next)
  },

  computed: {
    ...mapGetters(['getGuidedTours']),
    guidedTours() {
      return this.getGuidedTours
    }
  },

  methods: {
    tourClick(tour) {
      this.$router.push(`/guided-tours/${tour.slug}`)
    },
    loadTimeline() {
      tl = gsap.timeline({
        paused: true
      })
      this.guidedTours.forEach((item, index) => {
        const sectionDelay = (index * 0.1)
        const imgDelay = (index * 0.3)
        const btnDelay = (index * 0.3)
        tl.fromTo(`#guided-tour-${index}`, { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, delay: sectionDelay, duration: 0.6, ease: 'power1.inOut' }, 1)
        tl.fromTo(`#guided-tour-${index} .guided-tour-img`, { opacity: 0, x: 100 }, { opacity: 1, x: 0, delay: imgDelay, duration: 0.6, ease: 'power1.inOut' }, 2)
        tl.fromTo(`#guided-tour-${index} .guided-tour-btn`, { opacity: 0, x: -50 }, { opacity: 1, x: 0, delay: btnDelay, duration: 0.4, ease: 'power1.inOut' }, 3)
      })
    },
    animateOut(next) {
      this.$store.commit('toggleMenu', false)
      tl.timeScale(2.5).reverse()
      tl.eventCallback('onReverseComplete', () => {
        next()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .guided-tour {
    @screen md {
      transition: width 0.6s ease-in-out;
      &:hover {
        width: 45%;
      }
    }
  }
</style>
